<template>
  <y_layout>
    <el-card shadow="hover" :body-style="{padding:'1rem 1rem'}" style="width: 100%;">
      <div style="padding: 1rem;text-align: right">
        <el-button @click="addShow" size="medium" type="primary" icon="el-icon-edit">添加行业分类</el-button>
      </div>
      <el-table v-loading="loading" :data="list" border style="width: 100%">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column fixed="right" label="操作" align="center">
          <template #default="scope">
            <el-button @click="editShow(scope.row)" type="primary" size="small">编辑</el-button>
            <el-popconfirm style="margin-left: 1rem" @confirm="del(scope.row)" title="确定删除吗？">
              <el-button slot="reference" size="small">删除</el-button>
            </el-popconfirm>

          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
    <el-dialog title="编辑行业" :visible.sync="dialogTableVisible">
      <div class="flex-def flex-zCenter">
        <el-form :model="form" label-width="3rem" style="width: 30rem">
          <el-form-item label="名称">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item align="right">
            <el-button @click="submit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </y_layout>
</template>

<script>
import y_layout from "@/components/common/y_layout";
export default {
  name: "index",
  components:{y_layout},
  data(){
    return{
      dialogTableVisible:false,
      formInline:{
        status:0,
      },
      form:{
        title:"",
      },
      list:[],
      page:0,
      total:0,
      loading:false,
    }
  },
  mounted() {
    this.loadList()
  },
  methods:{
    pageChange(e){
      this.page = e;
      this.loadList();
    },
    loadList(){
      this.loading = true;
      this.$api.industry.page({page:this.page}).then(res=>{
        this.list = res.list;
        this.total = res.total;
        this.loading = false;
      })
    },
    addShow(){
      this.form = {title:""}
      this.dialogTableVisible = true;
    },
    editShow(e){
      this.form.id = e.id;
      this.form.title = e.title;
      this.dialogTableVisible = true;
    },
    del(item){
      this.$api.industry.del(item).then(()=>{
        this.$message.success("操作成功");
        this.dialogTableVisible = false;
        this.loadList();
      })
    },
    submit(){
      if(!this.form.id){
        this.$api.industry.add(this.form).then(()=>{
          this.$message.success("操作成功");
          this.dialogTableVisible = false;
          this.loadList();
        })
        return;
      }
      this.$api.industry.update(this.form).then(()=>{
        this.$message.success("操作成功");
        this.dialogTableVisible = false;
        this.loadList();
      })
    }
  }
}
</script>

<style scoped>

</style>